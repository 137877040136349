// Fonts
$body-font: ("Roboto", sans-serif) 400;
$bold-font: ("Roboto", sans-serif) 700;
$heading-font: ("PT San", sans-serif) 700;

// Colours
$white: #fff;
$black: #000;
$primary: #803680;
$secondary: #474131;
$text-black: rgba($black, 0.85);
$divider: #dfdfdf;

// Header
$header-bg-colour: $primary;
$header-text-color: $white;
$header-menu-button-bg-colour: $primary;
$header-main-shadow: 0 2px 5px rgb(0 0 0 / 10%);
$header-mobile-menu-border-top: 1px solid $divider;

// Nav
$nav-pseudo-element-colour: $primary;
$nav-pseudo-element-opacity: 1;
$nav-dropdown-chevron: $white;

// Typography
$typography-heading-transform: none;
$typography-heading-line-height: 1.1;
